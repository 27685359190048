import {
  getStoreRequest,
  getChangeCommissionLogRequest,
  getExportStoreRequest,
  createStore,
  deleteStore,
  updateStore,
  requestSettlement,
  requestUserDeposit,
  requestStoreDeposit,
  requestUserWithdraw,
  resetGoogleOpt,
  getCommission
} from "@/api/store";

const state = {
  listStore: {},
  changeCommissionLog: {},
  getAllStore: {},
  getAllStore2: {},
  detailStore: {},
  transfer: {},
  itemStore: null,
};

const getters = {
  getAllStore: (state) => {
    return state.getAllStore;
  },
  getItemStore: (state) => {
    return state.itemStore;
  },
};

const mutations = {
  handleStoreStore(state, payload) {
    state.listStore = payload;
  },
  handleUpdateAllStore(state, payload) {
    state.getAllStore = payload;
  },
  handleUpdateAllStore2(state, payload) {
    state.getAllStore2 = payload;
  },
  handleUpdateChangeCommissionLog(state, payload) {
    state.changeCommissionLog = payload;
  },
  handleGetDetailBankStore(state, payload) {
    state.detailStore = payload;
  },
  handleUpdateTransfer(state, payload) {
    state.transfer = payload;
  },
  setItemStore(state, payload) {
    state.itemStore = payload;
  },
};

const actions = {
  async getAffiliateStoreAction({ commit }, data) {
    const res = await getStoreRequest(data);
    if (res.data.status == 0) {
      commit("handleStoreStore", {
        list: res.data.data.filter(item => item.is_display == 1),
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
      commit("handleUpdateLoading", false);
    } else {
      commit("handleUpdateLoading", false);
    }
  },

  async getAllAffiliateStoreAction({ commit }, data) {
    const res = await getStoreRequest(data);
    commit("handleUpdateAllStore", res.data.data);
  },

  async getChangeCommissionLogAction({ commit }, data) {
    const res = await getChangeCommissionLogRequest(data);
    if (res.data.status == 0) {
      commit("handleUpdateChangeCommissionLog", {
        list: res.data.data,
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
      commit("handleUpdateLoading", false);
    } else {
      commit("handleUpdateLoading", false);
    }
  },

  async getAllAffiliateStoreAction2({ commit }, data) {
    const res = await getStoreRequest(data);
    commit("handleUpdateAllStore2", res.data.data);
  },

  async getExportFileStore({ commit }) {
    const res = await getExportStoreRequest();
    commit("handleUpdateLoading", false);
    return res;
  },

  async createStoreAction({ commit }, data) {
    const res = await createStore(data);
    return res;
  },

  async deleteStoreAction({ commit }, data) {
    const res = await deleteStore(data);
    return res;
  },

  async resetGoogleOtpAction({ commit }, data) {
    const res = await resetGoogleOpt(data);
    return res;
  },

  async handleUpdateStore({ commit }, data) {
    const res = await updateStore(data);
    return res.data;
  },

  async handleGetCommission({ commit }, data) {
    const res = await getCommission(data);
    return res.data;
  },

  async handleRequestSettlement({ commit }, data) {
    const res = await requestSettlement(data);
    return res.data;
  },

  async handleRequestUserDeposit({ commit }, data) {
    const res = await requestUserDeposit(data);
    return res.data;
  },

  async handleRequestStoreDeposit({ commit }, data) {
    const res = await requestStoreDeposit(data);
    return res.data;
  },

  async handleRequestUserWithdraw({ commit }, data) {
    const res = await requestUserWithdraw(data);
    return res.data;
  },
};

export default { state, getters, mutations, actions };
