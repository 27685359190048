import { SSRRequest, AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const getListUser = async (params) => {
  const res = await AuthRequest({
    url: `/api/users${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};

export const getMeRequest = async (data) => {
  try {
    const res = await SSRRequest({
      url: "/goods/member/get",
      method: "POST",
      data,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const changeProfileRequest = async (data) => {
  try {
    const res = await SSRRequest({
      url: "/member_new/update",
      method: "POST",
      data,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createUser = async (data) => {
  const res = await AuthRequest({
    url: "/api/users",
    method: "POST",
    data,
  });
  return res;
};

export const modifyingUser = async (data) => {
  let idx = data?.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/users/${idx}`,
    method: "PUT",
    data,
  });
  return res;
};

export const deleteUser = async (data) => {
  let idx = data?.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/users/${idx}`,
    method: "DELETE",
    data,
  });
  return res;
};

export const getUser = async (data) => {
  let idx = data?.idx;
  delete data["idx"];
  const res = await AuthRequest({
    url: `/api/users/${idx}`,
    method: "GET",
    data,
  });
  return res;
};
