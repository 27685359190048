import {
  loginRequest,
  getMeAuthRequest,
  getAdminInfoRequest,
  updateInfoMe,
  googleOtpRequest,
} from "@/api/auth/request";
import { getCookie } from "@/utils/cookie";
import i18n from "@/locales/index";
import { handleLogout } from "@/utils/function";

const state = {
  auth: false,
  gradeLogin: null,
  infoMe: {},
  activeSidebar: 0,
  activeMenu: 0,
  activeGuideMenu: 0,
  activeTabCustomer: 0,
  activeIndexNotice: null,
  activeIndexEvent: null,
  activeIndexFaq: null,
  activeIndexCustomer: null,
  activeIndexMessage: null,
  authInfor: {},
  message: "",
  loading: false,
  intervalId: null,
  intervalPing: null,
  activeNavBottom: -1,
  sidebar: false,
  selectOption : null,
  currentPage: 1,
  dataLogin: {
    account: "",
    password: "",
  },
  dataSignup: {
    account: "",
    password: "",
    nickname: "",
    phone: "",
    domain_info: "",
    bank_name: "",
    bank_account: "",
    bank_number: "",
    voter_account: "",
    withdraw_pass: "",
    confirmPassword: "",
  },
  errorSignup: {
    account: "",
    password: "",
    nickname: "",
    phone: "",
    domain_info: "",
    bank_name: "",
    bank_account: "",
    bank_number: "",
    voter_account: "",
    withdraw_pass: "",
    confirmPassword: "",
  },
  onWeb: true,
  activeGame: 0,
  activeDeposit: 2,
  activeWithdraw: 2,
  errorLogin: false,
  commission: 0,
};

const getters = {
  auth(state) {
    return state.auth;
  },
};

const mutations = {
  handleAuth(state, payload) {
    state.auth = payload;
  },
  handleActiveSidebar(state, number) {
    return (state.activeSidebar = number);
  },
  handleActiveNavBottom(state, number) {
    return (state.activeNavBottom = number);
  },
  handleToggleSidebar(state, payload) {
    return (state.sidebar = payload);
  },
  handleActiveGuideMenu(state, number) {
    return (state.activeGuideMenu = number);
  },
  handleActiveMenu(state, number) {
    return (state.activeMenu = number);
  },
  handleAuthTest(state, payload) {
    state.authInfor = payload;
    state.auth = true;
    state.sidebar = false;
  },
  handleUpdateMessage(state, payload) {
    state.message = payload;
  },
  handleUpdateLoading(state, payload) {
    state.loading = payload;
  },
  clearAuthInfor(state) {
    state.authInfor = {};
  },
  handleSetInterval(state, payload) {
    state.intervalId = payload;
  },
  handleClearInterval(state) {
    clearInterval(state.intervalId);
    state.intervalId = null;
  },
  handleSetIntervalPing(state, payload) {
    state.intervalPing = payload;
  },
  handleClearIntervalPing(state) {
    clearInterval(state.intervalPing);
    state.intervalPing = null;
  },
  handleChangeTabCustomer(state, payload) {
    state.activeTabCustomer = payload;
  },
  handleSelectNotice(state, payload) {
    state.activeIndexNotice = payload;
  },
  handleSelectEvent(state, payload) {
    state.activeIndexEvent = payload;
  },
  handleSelectFaq(state, payload) {
    state.activeIndexFaq = payload;
  },
  handleChangePage(state, payload) {
    state.currentPage = payload;
  },
  handleUpdateOnWeb(state, payload) {
    state.onWeb = payload;
  },
  handleActiveGame(state, payload) {
    state.activeGame = payload;
  },
  handleActiveDeposit(state, payload) {
    state.activeDeposit = payload;
  },
  handleActiveWithdraw(state, payload) {
    state.activeWithdraw = payload;
  },
  handleChangeErrorLogin(state, payload) {
    state.errorLogin = payload;
  },
  handleUpdateTree(state, payload) {
    state.tree = payload;
  },
  handleUpdateInfoMe(state, payload) {
    state.infoMe = payload;
  },
  handleUpdateSelectOption(state, payload) {
    state.selectOption = payload;
  },
  handleUpdateCommission(state, payload) {
    state.commission = payload;
  },
};

const actions = {
  async loginAction({ commit }, data) {
    const res = await loginRequest(data);
    if (res?.is_success) {
      // commit("handleAuthTest", res);
      commit("handleUpdateMessage", res?.message);
    }
    return res;
  },

  async googleOtpAction({ commit }, data) {
    const res = await googleOtpRequest(data);
    if (res?.is_success) {
      // commit("handleAuthTest", res);
      commit("handleUpdateMessage", res?.message);
    }
    return res;
  },

  async getMeAuthAction({ commit }) {
    const res = await getMeAuthRequest();

    if (res?.data?.status == 0) {
      commit("handleUpdateInfoMe", res?.data.data);
      return res?.data;
    } else {
      handleLogout();
    }
    return res;
  },

  async getAdminInfoAction({ commit }) {
    const res = await getAdminInfoRequest();
    return res;
  },

  async updateMeAuthAction({ dispatch }, data) {
    const res = await updateInfoMe(data.idx, data);
    if (res?.data.status == 0) {
      await dispatch("getMeAuthAction");
    }
    return res;
  },

  startIntervalAction({ commit }, payload) {
    commit("handleSetInterval", payload);
  },
  clearIntervalAction({ commit }) {
    commit("handleClearInterval");
  },
  startIntervalPingAction({ commit }, payload) {
    commit("handleSetIntervalPing", payload);
  },
  clearIntervalPingAction({ commit }) {
    commit("handleClearIntervalPing");
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
